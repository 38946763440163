body.shipping {
	font-family: 'Space Mono', monospace;
	color: #1c2123;
	background: #cbeeff;
}

.shipping{
	*,
	*::after,
	*::before {
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
	}

	a {
		text-decoration: none;
		color: #555;
		outline: none;
	}

	a:hover,
	a:focus {
		color: #333;
	}

	.hidden {
		position: absolute;
		overflow: hidden;
		width: 0;
		height: 0;
		pointer-events: none;
	}

	/* Icons */
	.icon {
		display: block;
		width: 1.5em;
		height: 1.5em;
		margin: 0 auto;
		fill: currentColor;
		pointer-events: none;
	}

	.icon--arrow-up {
		transform: rotate3d(0,0,1,90deg);
	}

	.icon--arrow-down {
		transform: rotate3d(0,0,1,-90deg);
	}

	/* Page Loader */
	.js .loading::before {
		content: '';
		position: fixed;
		z-index: 100;
		top: 0;
		left: 0;
		display: flex;
		width: 100%;
		height: 100%;
		text-align: center;
		color: #fff;
		background: rgba(206, 209, 223, 0.9);
	}

	.js .loading::after {
		content: '';
		position: fixed;
		z-index: 10000;
		top: 50%;
		left: 50%;
		width: 54px;
		height: 70px;
		margin: -35px 0 0 -27px;
		pointer-events: none;
		border: 4px solid #fff;
		transition: opacity 0.3s;
		animation: loaderAnim 0.8s ease-out infinite alternate forwards;
	}

	@keyframes loaderAnim {
		to {
			opacity: 0.3;
			transform: translate3d(0,-100px,0);
		}
	}

	/* Header */
	.codrops-header {
		position: relative;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		align-items: center;
		width: 100%;
		padding: 2em 2em 2em 1em;
	}

	.codrops-header__title {
		font-size: 2em;
		font-weight: normal;
		margin: 0;
		padding: 0;
	}

	.github {
		margin: 0 0 0 auto;
	}

	.intro {
		display: none;
		position: absolute;
		z-index: 2;
		top: 0;
		left: 50%;
		pointer-events: none;
	}

	.intro__img {
		opacity: 0.8;
	}

	.intro__text {
		font-size: 1.25em;
		font-style: italic;
		position: absolute;
		bottom: 2em;
		left: 35%;
		width: 150%;
		max-width: 33vw;
		margin: 0;
	}

	.content{
		display: flex;
    flex-direction: column;
    max-width: 788px;
    margin: auto;
    margin-bottom: 80px;
    padding: 30px;
	}

}