.footer {
  margin: 0 0 0 25vw;
  padding: 3em;
  color: #fff;
  background: #1c2123;
}

.footer .footer-icon {
  position: relative;
}

.footer .footer-icon img{
  width: 133px;
  position: absolute;
  top: -94px;
  left: -18px;
}

.footer a {
  color: #7bd4ff;
}

.footer a:hover,
.footer a:focus {
  color: #ffadc9;
}

.columns {
  display: flex;
}

.column {
  width: 33.33%;
  padding: 0 1em;
}

.block-link {
  display: block;
}

@media screen and (max-width: 641px) {
  .footer .footer-icon img{
    width: 133px;
    top: -120px;
    left: -18px;
  }
}