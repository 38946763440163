.menu_logo img{
  width: 130px;
  position: absolute;
  top: -12px;
  left: 20px;
}

.uk-navbar-nav{
  margin-right: 10px;
}

.navbar-info{
  font-family: Staatliches;
  display: flex;
  justify-content: space-around;
  background-color: #f3fbff;
  padding: 10px 0;
  list-style-type: none;
  white-space: nowrap;
  overflow-x: auto;
  cursor: pointer;
}

.navbar-info .navbar-info-content {
  display: flex;
  align-items: center;
  line-height: 18px;
}

.navbar-info .navbar-info-content .navbar-info-icon{
  width: 40px;
  margin-right: 15px;
}

.navbarModal .modal-img{
  margin-bottom: 30px;
}

.navbarModal .modal-img iframe{
  cursor: none;
}



@media screen and (max-width: 768px) {
  .navbar-info{
    background: linear-gradient(to right, white 28px, white 95%, #00000017);
    justify-content: flex-start;
    padding: 20px;
  }
  .navbar-info div{
    margin-right: 30px;
  }
  .uk-navbar-left{
    width: 250px;
  }
  .uk-navbar-right{
    list-style-type: none;
    white-space: nowrap;
    overflow-x: auto;
    background: linear-gradient(to right, white 28px, white 95%, #00000017);
  }

}

@media screen and (max-width: 450px) {
  .uk-navbar-left{
    width: 300px;
  }

}