.pre-loader{
  
  * {
    box-sizing: border-box;
  }

  button{
    width: 100%;
    padding: 15px;
    background-color: #f2f87d;
    border: none;
    border-radius: 5px;
    opacity: 1;
    transition: 0.1s opacity ease-in-out;
  }

  button:hover{
    opacity: 0.85;
  }
  
  body {
    font-family: "Lato", sans-serif;
    line-height: 1.2;
    background-color: #f6f67e;
  }
  
  h1 {
    font-size: 40px;
    font-weight: 900;
    color: #ffffff;
    margin-bottom: 12px;
  }
  
  h2 {
    font-size: 22px;
    color: #ffffff;
    margin-bottom: 12px;
  }

  #loader-container{
    background-color: #333;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 999999;
  }
  
  main {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    align-items: flex-start;
    padding: 24px;
    width: 100%;
    height: 100%;
    max-width: 600px;
  }

  main .container, .DayPickerInput{
    width: 100%;

    .DayPickerInput-OverlayWrapper{
      position: absolute;
      bottom: 400px;
    }

  }

  main .content{
    text-align: center;
    margin-bottom: 50px;
  }
  
  
  #birthday-datepicker {
    margin-top: 36px;
    width: 100%;
  }
  @media screen and (min-width: 400px) {
    #birthday-datepicker {
      display: -webkit-box;
      display: flex;
    }
  }
  #birthday-datepicker .form-item {
    width: 100%;
    position: relative;
  }
  @media screen and (min-width: 400px) {
    
  }
  #birthday-datepicker label {
    display: block;
    margin-bottom: 8px;
    color: white;
  }
  #birthday-datepicker input {
    display: block;
    margin-bottom: 8px;
    padding-bottom: 6px;
    width: 100%;
    font-family: "Lato", sans-serif;
    font-size: 22px;
    font-weight: bold;
    text-align: left;
    color: #f6f67e;
    background-color: transparent;
    border: none;
    border-bottom: 2px solid #ffffff;
    border-radius: 0;
    outline: none;
    -webkit-transition: border-color .2s ease-out;
    transition: border-color .2s ease-out;
  }
  #birthday-datepicker input:first-child, #birthday-datepicker input:last-child {
    border-radius: 0;
  }
  #birthday-datepicker input::-webkit-input-placeholder {
    color: #f6f67e;
    font-size: 14px;
    line-height: 30px;
    -webkit-transition: color .2s .2s ease-out;
    transition: color .2s .2s ease-out;
  }
  #birthday-datepicker input:-moz-placeholder {
    color: #f6f67e;
    font-size: 14px;
    line-height: 30px;
    -moz-transition: color .2s .2s ease-out;
    transition: color .2s .2s ease-out;
  }
  #birthday-datepicker input::-moz-placeholder {
    color: #f6f67e;
    font-size: 14px;
    line-height: 30px;
    -moz-transition: color .2s .2s ease-out;
    transition: color .2s .2s ease-out;
  }
  #birthday-datepicker input:-ms-input-placeholder {
    color: #f6f67e;
    font-size: 14px;
    line-height: 30px;
    -ms-transition: color .2s .2s ease-out;
    transition: color .2s .2s ease-out;
  }
  #birthday-datepicker input:focus {
    border-bottom-color: #f6f67e;
  }
  #birthday-datepicker input:focus::-webkit-input-placeholder {
    color: transparent;
    -webkit-transition: color .2s ease-out;
    transition: color .2s ease-out;
  }
  #birthday-datepicker input:focus:-moz-placeholder {
    color: transparent;
    -moz-transition: color .2s ease-out;
    transition: color .2s ease-out;
  }
  #birthday-datepicker input:focus::-moz-placeholder {
    color: transparent;
    -moz-transition: color .2s ease-out;
    transition: color .2s ease-out;
  }
  #birthday-datepicker input:focus:-ms-input-placeholder {
    color: transparent;
    -ms-transition: color .2s ease-out;
    transition: color .2s ease-out;
  }
  #birthday-datepicker .date-text {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    top: calc(100% + 6px);
    font-size: 12px;
    color: white;
  }
  @media screen and (min-width: 400px) {
    #birthday-datepicker .date-text {
      top: 100%;
    }
  }
  
  .datepicker.dropdown-menu {
    padding: 0;
    background-clip: border-box;
    border: none;
    border-radius: 0;
    -webkit-animation: popup .2s ease-out forwards;
            animation: popup .2s ease-out forwards;
  }
  .datepicker.dropdown-menu table {
    background-color: #f6f67e;
  }
  .datepicker.dropdown-menu table thead {
    background-color: #f6f67e;
  }
  .datepicker.dropdown-menu table thead tr {
    border-top: 0;
  }
  .datepicker.dropdown-menu table tbody {
    background-color: white;
  }
  .datepicker.dropdown-menu table tr {
    border-top: 1px solid #ededed;
  }
  .datepicker.dropdown-menu table tr td, .datepicker.dropdown-menu table tr th {
    width: auto;
    height: 24px;
    padding: 8px;
    font-size: 16px;
    border-radius: 0;
    -webkit-transition: background-color .6s ease-out;
    transition: background-color .6s ease-out;
  }
  @media screen and (min-width: 400px) {
    .datepicker.dropdown-menu table tr td, .datepicker.dropdown-menu table tr th {
      padding: 12px;
    }
  }
  .datepicker.dropdown-menu table tr td:hover, .datepicker.dropdown-menu table tr td.active:hover, .datepicker.dropdown-menu table tr td:active:hover, .datepicker.dropdown-menu table tr th:hover, .datepicker.dropdown-menu table tr th.active:hover, .datepicker.dropdown-menu table tr th:active:hover {
    border-color: #ededed;
    border-radius: 0;
    -webkit-transition: background-color .2s ease-out;
    transition: background-color .2s ease-out;
  }
  .datepicker.dropdown-menu table tr td.focused, .datepicker.dropdown-menu table tr td.selected, .datepicker.dropdown-menu table tr th.focused, .datepicker.dropdown-menu table tr th.selected {
    background-color: #f6f67e;
    border-color: #ededed;
    border-radius: 0;
  }
  .datepicker.dropdown-menu table tr td.active, .datepicker.dropdown-menu table tr td.active:focus, .datepicker.dropdown-menu table tr td.active.active:hover, .datepicker.dropdown-menu table tr td:active:focus, .datepicker.dropdown-menu table tr td.highlighted, .datepicker.dropdown-menu table tr th.active, .datepicker.dropdown-menu table tr th.active:focus, .datepicker.dropdown-menu table tr th.active.active:hover, .datepicker.dropdown-menu table tr th:active:focus, .datepicker.dropdown-menu table tr th.highlighted {
    background-color: #f6f67e;
    border-color: #ededed;
  }
  .datepicker.dropdown-menu table tr th.datepicker-switch {
    font-size: 12px;
    font-weight: bold;
    line-height: 2;
    text-transform: uppercase;
    letter-spacing: .05em;
    color: #ffffff;
    background-color: inherit;
    border-radius: 0;
  }
  .datepicker.dropdown-menu table tr th.prev,
  .datepicker.dropdown-menu table tr th.next {
    color: #ffffff;
    background-color: #f6f67e;
    border-radius: 0;
  }
  .datepicker.dropdown-menu table tr th.dow {
    padding: 2px 12px;
    font-size: 12px;
    line-height: 2;
    color: #ffffff;
    background-color: #f6f67e;
    border-top: 1px solid #f6f67e;
    border-radius: 0;
  }
  .datepicker.dropdown-menu table tr td.day {
    width: 24px;
    border-right: 1px solid #ededed;
  }
  .datepicker.dropdown-menu table tr td.day:last-child {
    border-right-width: 0;
  }
  .datepicker.dropdown-menu table tr td.range {
    background-color: #eeeeee;
  }
  .datepicker.dropdown-menu .new,
  .datepicker.dropdown-menu .old,
  .datepicker.dropdown-menu .disabled,
  .datepicker.dropdown-menu .disabled:hover {
    color: #ccc;
  }
  
  [class*="fontawesome-"]:before {
    font-family: 'FontAwesome', sans-serif;
  }
  
  .fontawesome-calendar {
    position: absolute;
    top: 26px;
    right: 0;
    font-size: 24px;
    z-index: -99;
  }
  
  @-webkit-keyframes popup {
    from {
      opacity: 0;
      -webkit-transform: translateY(12px);
              transform: translateY(12px);
    }
    to {
      opacity: 1;
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }
  
  @keyframes popup {
    from {
      opacity: 0;
      -webkit-transform: translateY(12px);
              transform: translateY(12px);
    }
    to {
      opacity: 1;
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }
  
}